import { useEffect, useState } from "react";

const useIP = () => {
  const [ipData, setIpData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [countryCode, setCountryCode] = useState("ae");
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [countryLabel, setCountryFullName] = useState("");

  useEffect(() => {
    fetch("https://api.seeip.org/geoip")
      .then((res) => res.json())
      .then((data) => {
        setIpData(data);
        setCountryCode(data?.country_code?.toLowerCase());
        setRegion(data?.region)
        setCity(data?.city)
        setCountryFullName(data?.country)
        if (data?.ip && data.ip !== ipAddress) {
          setIpAddress(data.ip);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ useIP:", err)
      })
  }, []); // Run only once on mount

  return {
    ...ipData,
    ipAddress,
    countryCode,
    city,
    region,
    countryLabel,
  };
};

export default useIP;
